import React, { Component } from 'react';
import { graphql } from 'gatsby';
import PageBuilder from 'components/PageBuilder';
import HomeHero from 'components/HomeHero';
import Seo from 'components/Seo';

class Home extends Component {
  render() {
    const {
      data: {
        craft: { entry },
      },
    } = this.props;

    return (
      <>
        <Seo title={entry.seoTitle} description={entry.seoDescription} />
        {entry.homeHero.length > 0 && <HomeHero homeHero={entry.homeHero} />}
        {entry.pageBuilder.length > 0 && (
          <PageBuilder pageBuilder={entry.pageBuilder} />
        )}
      </>
    );
  }
}

export default Home;

export const homeQuery = graphql`
  query HomeQuery {
    craft {
      globals {
        generalContent {
          emailAddress
          phoneNumber
          postalCode
          city
          address1
          address2
        }
      }

      entry(slug: "home") {
        ... on Craft_Home {
          seoTitle
          seoDescription
          homeHero {
            ...HomeHeroQuery
          }
          pageBuilder {
            ...PageBuilderQuery
          }
        }
      }
    }
  }
`;
