import React from 'react';

const IconArrowDown = props => (
  <svg
    viewBox="0 0 40 22.4"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={props.width ? props.width : '40px'}
    height={props.height ? props.height : '22.4px'}
    className={props.className}
  >
    <path d="M35.3 0L20 13.845 4.7 0 0 4.262 20 22.4 40 4.262z" fill="#fff" />
  </svg>
);

export default IconArrowDown;
